<template>
  <div>
    <div class="d-none">
      <h2 class="section-header">
        <img src="@/assets/images/recruit-black.svg" alt="recruit" />
        Recruit Smart Asscociates
      </h2>

      <div class="form">
        <h3>Recruit Smart Asscociates</h3>
        <div class="fields">
          <div class="primary-fields">
            <div class="line-group">
              <div class="input-group">
                <input type="text" placeholder="First Name" v-model="firstName" />
                <p class="error" v-if="firstNameError">{{ firstNameError }}</p>
              </div>

              <div class="input-group">
                <input type="text" placeholder="Last Name" v-model="lastName" />
                <p class="error" v-if="lastNameError">{{ lastNameError }}</p>
              </div>
            </div>

            <div class="line-group">
              <div class="input-group">
                <input type="text" placeholder="Phone" v-model="phoneNumber" />
                <p class="error" v-if="phoneNumberError">
                  {{ phoneNumberError }}
                </p>
              </div>

              <div class="input-group">
                <input type="email" placeholder="Email Address" v-model="email" />
                <p class="error" v-if="emailError">{{ emailError }}</p>
              </div>
            </div>
          </div>

          <button class="update" @click="recruit()" :disabled="processing">
            <img src="@/assets/images/add-user.svg" alt="recruit user" />
            {{ processing ? "Recruiting..." : "Recruit" }}
          </button>
        </div>
      </div>
    </div>

    <div class="top-card">
        <h4>Invite a friend</h4>
        <h6>
          Level up your experience! <br>
          Grow in this awesome community and recruit your friends. <br>
          Every connection earns you fantastic rewards and exclusive bonuses. <br>
          It's not just a referral, it's an adventure!
        </h6>
        <div class="link-holder">
          <div class="row">
            <div class="col-md-8">
              <div>
                <p v-if="subscription == 'YES'">{{ userLink }}</p>
                <p v-else>You are not subscribe</p>
              </div>
            </div>
            <div class="col-md-4" v-if="subscription == 'YES'">
              <div class="copy-invite-btn">
                <button class="btn copy" @click="copy()">Copy link</button>
                <button class="btn invite" data-toggle="modal" data-target="#inviteModal">Invite friend</button>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="how-it-works-holder">
      <!-- <h2>Refer a friend</h2> -->
      <h3>How it works</h3>
      <div class="row">
        <div class="col-md-4">
          <div class="hiw-card">
            <p>Step 1</p>
            <h4>Send Your Unique Referral Link</h4>
            <h6>Share the magic! Copy your exclusive referral link and spread the word to your friends.</h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="hiw-card">
            <p>Step 2</p>
            <h4>Sign Up with Your Referral Code</h4>
            <h6>Your friends dive into the excitement, signing up using your special referral code. It's that simple!</h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="hiw-card">
            <p>Step 3</p>
            <h4>Reap the Rewards</h4>
            <h6>As your circle grows, so do your rewards! Enjoy the perks that come your way – it's our way of saying thanks.</h6>
          </div>
        </div>
      </div>
    </div>
    

    <!-- Modal to invite a friend starts -->
    <div class="modal fade" tabindex="-1" id="inviteModal">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <div>
                    <h5 class="modal-title">
                    Invite friend
                  </h5>
                  <p>Provide the details of your friend</p>
                  </div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <form>
                    <div class="form-group input-type">
                      <label for="fname">First name</label>
                      <input type="text" v-model="firstname" class="form-control" id="fname" placeholder="First name">
                    </div>
                    <div class="form-group input-type">
                      <label for="lname">Last name</label>
                      <input type="text" v-model="lastname" class="form-control" id="lname" placeholder="Last name">
                    </div>
                    <div class="form-group input-type">
                      <label for="email">Email address</label>
                      <input type="email" v-model="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Email address">
                    </div>
                    <div class="form-group input-type">
                      <label for="max">Phone number</label>
                      <input 
                      v-model="phone"
                      oninput="javascript: if(this.value.length > this.maxLength) this.value = this.value = this.value.slice(0, this.maxLength);"
                      maxLength="11"
                      type="number" 
                      class="form-control" 
                      id="max" placeholder="Phone number">
                    </div>

                    <div class="form-group">
                      <button :disabled="processing" class="btn submit-invite" @click.prevent="inviteFriend()">
                        <span :class="{'blink-text' : processing}">{{ processing ? "Sending invite..." : "Submit Invite" }}</span>
                      </button>
                    </div>
                  </form>
              </div>
          
          </div> 
      </div>
    </div>
    <!-- Modal to invite a friend ends -->
   
  </div>
  
</template>

<script>
import recruitRealtor from "../services/recruitRealtor";
import $ from "jquery";
// import Validators from "../helpers/Validators";
export default {
  name: "Account",
  data() {
    return {
      firstName: "",
      firstNameError: "",
      firstname: "",
      lastname: "",
      phone: "",
      lastName: "",
      lastNameError: "",
      phoneNumber: "",
      phoneNumberError: "",
      email: "",
      emailError: "",
      processing: false,
      userLink: "",
      user: "",
    };
  },
  mounted() {
    // this.subscription;
    this.checkUserDetails();
  },
  created() {
    this.subscription = this.$store.getters.getSubscription;
    const user = this.$store.getters.getUser;
    this.userID = user.id;
    const userCode = this.$store.getters.getUser.usercode;
    this.userLink = window.location.origin + "/" + userCode;
  },
  methods: {
    checkUserDetails() {
      this.user = JSON.parse(localStorage.getItem("shelterUser")).user;
      if (this.user.smartfm == 1) {
        this.appliedForFMIO = true;
      }
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.userLink);
        this.$toast.success("copied");
      } catch (err) {
        this.$toast.error("Failed to copy");
      }
    },

    async inviteFriend() {
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe to recruit");
      }
      let body = {
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone
      };
      body = JSON.stringify(body);
      this.processing = true;
      if (this.firstname == "" || this.lastname == "" || this.phone == "" || this.email == "") {
        this.processing = false;
        return this.$toast.error("All fields are required");
      }
      if (this.phone.length < 8) {
        this.processing = false;
        return this.$toast.error("Phone number should not be less than eight (8) digits");
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        this.processing = false;
        return this.$toast.error("Enter a valid email");
      }
      try {
        await recruitRealtor
          .add(body)
          .then(data => {
            this.processing = false;
            if (data.success) {
              this.$toast.success("success");
              this.reset();
              $("#inviteModal")
              .removeClass("fade")
              .modal("hide");
            } else {
              this.$toast.error('Error');
            }
          })
          .catch(() => {
            this.profilePicStatus = "";
            this.$toast.error("please check your network and refresh the page");
          })
      } finally {
        this.processing = false; // console.log("closed");
      }
    },

    // recruit() {
    //   if (this.subscription != "YES") {
    //     return this.$toast.error("Subscribe to recruit");
    //   }
    //   const forFirstName = new Validators();
    //   forFirstName.required(this.firstName);
    //   forFirstName.onlyLetters(this.firstName);
    //   this.firstNameError = forFirstName.errors;

    //   const forLastName = new Validators();
    //   forLastName.required(this.lastName);
    //   forLastName.onlyLetters(this.lastname);
    //   this.lastNameError = forLastName.errors;

    //   const forEmail = new Validators();
    //   forEmail.required(this.email);
    //   forEmail.validEmail(this.email);
    //   this.emailError = forEmail.errors;

    //   const forPhoneNumber = new Validators();
    //   forPhoneNumber.onlyNumbers(this.phoneNumber);
    //   this.phoneNumberError = forPhoneNumber.errors;

    //   if (
    //     this.firstNameError.length === 0 &&
    //     this.lastNameError.length === 0 &&
    //     this.phoneNumberError.length === 0
    //   ) {
    //     // TODO: Ensure that there is a change in point when you add a new realtor.
    //     // just check to ensure that the backend is connected well to the frontend.
    //     let body = {
    //       email: this.email,
    //       firstname: this.firstName,
    //       lastname: this.lastName,
    //       phone: this.phoneNumber
    //     };

    //     body = JSON.stringify(body);

    //     this.processing = true;
    //     recruitRealtor
    //       .add(body)
    //       .then(data => {
    //         if (data.success) {
    //           this.$toast.success("You have successfully added a realtor");
    //           this.reset();
    //         } else {
    //           this.$toast.error(data.error);
    //         }
    //       })
    //       .catch(() => {
    //         this.profilePicStatus = "";
    //         this.$toast.error("please check your network and refresh the page");
    //       })
    //       .finally(() => {
    //         this.processing = false;
    //       });
    //   }
    // },
    reset() {
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.phone = "";
    }
  }
};
</script>

<style lang="scss" scoped>
/* @import "../styles/section-header.css";
@import "../styles/forms.css"; */

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;
.top-card {
  background: $white;
  padding: 40px 24px;
  border-radius: 8px;

  h4 {
    font-family: Lato;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    text-align: left;
    color: $offblack;
  }
  h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $asheGrey;
  }
}

.link-holder {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-top: 20px;
  p {
    padding: 16px 8px;
    background: #f5f5f7;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $offblack;
    border-radius: 4px;
    word-wrap: break-word;
    min-height: 48px;
  }
}

.copy-invite-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .copy, .invite {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    min-height: 48px;
    box-shadow: none;
    outline: 0;
  }
  .copy {
    background: #DDE4FC;
    color: $primary;
    margin-right: 10px;
  }
  .invite {
    background: $primary;
    color: $white;
  }
}

.how-it-works-holder {
  margin-top: 30px;
  // h2 {
  //   font-family: Lato;
  //   font-size: 38px;
  //   font-weight: 700;
  //   line-height: 45px;
  //   text-align: left;
  //   color: #000A2F;
  //   margin-bottom: 20px;
  // }
  h3 {
    font-family: Lato;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: #000A2F;
    margin-bottom: 20px;
  }
}

.hiw-card {
  background: $white;
  Padding: 24px 24px 40px 24px;
  border-radius: 8px;
  min-height: 200px;
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    text-align: left;
    color: $asheGrey;
    margin-bottom: 5px !important;
  }
  h4 {
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: $offblack;
  }
  h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $asheGrey;
  }
}

.input-type {
  label {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: left;
    color: $offblack;
  }
  input {
    height: 48px;
    // box-shadow: 0px 8px 8px -4px #1018280A;
    border: 1px solid #E7E7E7;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $offblack;
    outline: 0;
    box-shadow: none;
  }
}

.submit-invite {
  background: $primary;
  color: $white;
  height: 48px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-top: 30px;
  box-shadow: none;
  outline: 0;
}

.blink-text {
  animation: animate 1.5s linear infinite; 
}
@keyframes animate{ 
   0%{ 
     opacity: 0; 
   } 
   50%{ 
     opacity: 0.7; 
   } 
   100%{ 
     opacity: 0; 
   } 
 } 

::-ms-input-placeholder { /* Edge 12-18 */
  color: #CCCED5;
}

::placeholder {
  color: #CCCED5;
}

@media screen and (max-width: 599px) {
  .top-card {
    margin-top: 20px;
    h4 {
      font-size: 24px;
    }
  }
  .how-it-works-holder {
    // h2 {
    //   font-size: 25px;
    // }
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  .link-holder {
    p {
        font-size: 12px;
    }
  }
  .copy-invite-btn {
    margin-top: 30px;
    justify-content: center;
    button {
      width: 100%;
    }
  }
  .hiw-card {
    margin-bottom: 10px;
    h4 {
      font-size: 18px;
    }
  }
}

</style>
